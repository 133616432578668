import React from 'react';

// const IconLoader = () => (
//   <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//     <title>Loader Logo</title>
//     <g>
//       <g id="B" transform="translate(11.000000, 5.000000)">
//         <path
//           d="M 16 67 L 16 17 L 31 17 L 40 28 L 48 17 L 63 17 L 63 67 L 47 67 L 47 40 L 46 40 L 41 52 L 39 52 L 33 40 L 32 40 L 32 67"
//           fill="currentColor"
//         />
//       </g>
//       <path
//         stroke="currentColor"
//         strokeWidth="5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M 50, 5
//                   L 11, 27
//                   L 11, 72
//                   L 50, 95
//                   L 89, 73
//                   L 89, 28 z"
//       />
//     </g>
//   </svg>
// );

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    role="img"
    width="128"
    height="128"
    viewBox="0 0 128 128"
  >
    <title>Logo</title>
    <g>
      <circle id="svg_1" cx="64" cy="64" r="60" stroke="#E60068" strokeWidth="7" />
      <path
        id="svg_2"
        d="M46.7386 93V34.8182H57.2784V84.1648H82.9034V93H46.7386Z"
        fill="#E60068"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default IconLoader;
