module.exports = {
  general: {
    lastName: 'Collares',
    firstName: 'Leandro',
    email: 'leandro.collares@gmail.com',
    title: 'Data, UX',
    location: 'Outer Rim',
  },
  skills: ['Data visualization', 'Data analysis', 'UX research'],
  techs: [
    'JavaScript',
    'GeoJSON/TopoJSON',
    'D3',
    'Plotly',
    'Svelte',
    'Folium',
    'Vue',
    'Pandas',
    'React',
    'Axios',
    'SVG',
    'Lodash',
  ],
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/leandrocollares/',
    },
    // {
    //   name: 'Instagram',
    //   url: 'https://www.instagram.com/hamza.pixl/',
    // },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/lcollares/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/lcollares_',
    },
  ],
  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    // {
    //   name: 'Experience',
    //   url: '/#jobs',
    // },
    {
      name: 'Work',
      url: '/#projects',
    },
    // {
    //   name: 'Posts',
    //   url: '/#posts',
    // },
    // {
    //   name: 'Contact',
    //   url: '/#contact',
    // },
  ],
  colors: {
    highlight: '#ff3344',
    lightDark: '#0d1b2a',
    dark: '#25466b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
