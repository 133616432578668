import React from 'react';

// const IconLogo = () => (
//   <svg
//     id="logo"
//     xmlns="http://www.w3.org/2000/svg"
//     fill="currentColor"
//     stroke="currentColor"
//     strokeWidth="24"
//     role="img"
//     width="24"
//     height="24"
//     viewBox="0 0 454 454">
//     <title>Logo</title>
//     <g>
//       <path
//         d="M0,0v454h454V0H0z M331.204,345.802H266.4v-86.399h-78.801v86.399h-64.803V108.198h64.803v86.4H266.4v-86.4h64.804V345.802
// 		L331.204,345.802z"
//       />
//     </g>
//   </svg>
// );

// const IconLogo = () => (
//   <svg
//     id="logo"
//     xmlns="http://www.w3.org/2000/svg"
//     fill="currentColor"
//     stroke="currentColor"
//     role="img"
//     width="64"
//     height="64"
//     viewBox="0 0 128 128"
//   >
//     <title>Logo</title>
//     <g>
//       <circle id="svg_1" strokeWidth="8" stroke="#E60068" r="48.5" cy="64" cx="64" />
//       <path
//         id="svg_2"
//         d="m41.61,34l24.66,0l0,2.61c-1.44,0.54 -2.88,1.02 -4.32,1.44c-1.44,0.36 -2.88,0.63 -4.32,0.81l0,53.46l20.7,0l5.31,-14.49l2.34,0c0.18,1.26 0.33,2.73 0.45,4.41c0.18,1.62 0.3,3.33 0.36,5.13c0.12,1.74 0.18,3.45 0.18,5.13c0,1.68 -0.03,3.18 -0.09,4.5l-45.27,0l0,-2.61c1.44,-0.66 2.76,-1.14 3.96,-1.44c1.2,-0.3 2.34,-0.54 3.42,-0.72l0,-53.37c-1.38,-0.24 -2.7,-0.54 -3.96,-0.9c-1.26,-0.42 -2.4,-0.87 -3.42,-1.35l0,-2.61z"
//         fill="#E60068"
//         strokeWidth="2"
//       />
//     </g>
//   </svg>
// );

const IconLogo = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    role="img"
    width="128"
    height="128"
    viewBox="0 0 128 128"
  >
    <title>Logo</title>
    <g>
      <circle id="svg_1" cx="64" cy="64" r="60" stroke="#E60068" strokeWidth="7" />
      <path
        id="svg_2"
        d="M46.7386 93V34.8182H57.2784V84.1648H82.9034V93H46.7386Z"
        fill="#E60068"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default IconLogo;
